<template>
  <div class="forecast-results">
    <div class="column" style="margin-bottom:0" v-loading="loading">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>我的预测统计</div>
          <div class="margin-left-20">
            <span class="color-sub">选择时间：</span>
            <el-date-picker
              style="width: 220px;"
              size="mini"
              :key="modelFreq"
              v-model="cycle"
              :type="modelFreq=='week'?'week':'month'"
              value-format="yyyy-MM-dd"
              :format="modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM'"
              placeholder="选择日期"
              :clearable="false"
              @change="getPredictPlan">
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <el-button plain size="mini" @click="downLoadDataByModelResult">导出预测统计</el-button>
        </div>
      </div>  
      <el-table
        ref="datatable"
        :height="$root.docHeight-215"
        size="medium"
        :data="dataList"
        :key="tableKey"
      >
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column label="预测产品" prop="productName" width="100"></el-table-column>
        <el-table-column label="预测目标" prop="targetName" min-width="150"></el-table-column>
        <el-table-column label="单位" prop="unit" width="80"></el-table-column>
        <el-table-column align="center">
          <template slot="header">
            {{ dataList[0]?dataList[0].predictCycle:'' | dateFormat(modelFreq=='month'?'yyyy-MM':'yyyy-MM-dd')}}
          </template>
          <el-table-column label="真实值" prop="his">>
            <template slot-scope="props">{{ props.row.his | numFormat(2,'-') }}</template>
          </el-table-column>
          <el-table-column label="模型自估预测" prop="aiPredictValue">
            <template slot-scope="props">{{ props.row.aiPredictValue | numFormat(2,'-') }}</template>
          </el-table-column>
          <el-table-column label="偏差率" prop="aiDeviationRate">
            <template slot-scope="props">{{ props.row.aiDeviationRate | numFormat(2,'-') }}{{$numFormat(props.row.aiDeviationRate,2)?'%':'' }}</template>
          </el-table-column>
          <el-table-column label="卓创赋值预测" prop="sciPredictValue">
            <template slot-scope="props">{{ props.row.sciPredictValue | numFormat(2,'-') }}</template>
          </el-table-column>
          <el-table-column label="偏差率" prop="sciDeviationRate">
            <template slot-scope="props">{{ props.row.sciDeviationRate | numFormat(2,'-') }}{{$numFormat(props.row.sciDeviationRate,2)?'%':'' }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            {{ dataList[0]?dataList[0].nextPredictCycle:'' | dateFormat(modelFreq=='month'?'yyyy-MM':'yyyy-MM-dd')}}
          </template>
          
          <el-table-column label="模型自估预测" prop="aiPredictValue">
            <template slot-scope="props">{{ props.row.nextAiPredictValue | numFormat(2,'-') }}</template>
          </el-table-column>
         
          <el-table-column label="卓创赋值预测" prop="sciPredictValue">
            <template slot-scope="props">{{ props.row.nextSciPredictValue | numFormat(2,'-') }}</template>
          </el-table-column>
          
        </el-table-column>
        <el-table-column label="操作" prop="" width="110">
          <template slot-scope="props">
            <div class="flex-row">
              <div class="link-text" @click="getDivisorsOfModel(props.row)">查看模型构成</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <!-- 预测结果弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="resultDetail.visible" class="forecast-results-dialog"
        title="本期模型构成"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="80%">
          <div slot="title" class="flex-row align-items-center justify-between margin-right-30">
            <div class="el-dialog__title">本期模型构成</div>
            <el-button plain size="mini" @click="downLoadDataByDivisorResult">导出模型构成</el-button>
          </div>
         
          <el-row :gutter="20" type="flex" v-loading="resultDetail.loading">
            <el-col :span="12">
              <div>
                <el-table
                  ref="divisorsTable"
                  :height="$root.docHeight-300"
                  size="medium"
                  :data="resultDetail.data"
                  :key="activeRow.modelId+'_'+resultDetail.data.length"
                  highlight-current-row
                  @current-change="handleCurrentChange"
                >
                  <div slot="empty"><el-empty :image-size="150"></el-empty></div>
                  <el-table-column label="因子" prop="divisorName"></el-table-column>
                  <el-table-column label="单位" prop="unit" width="80"></el-table-column>
                  <el-table-column align="center">
                    <template slot="header">
                      {{ resultDetail.data[0]?resultDetail.data[0].predictCycle:'' | dateFormat(modelFreq=='month'?'yyyy-MM':'yyyy-MM-dd')}}
                    </template>
                    <el-table-column label="真实值" prop="hisValue" width="100">
                      <template slot-scope="props">{{ props.row.hisValue | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="模型自估" prop="aiPredictValue" width="100">
                      <template slot-scope="props">{{ props.row.aiPredictValue | numFormat(2,'-') }}</template>
                    </el-table-column>
                   
                    <el-table-column label="卓创赋值" prop="sciPredictValue" width="100">
                      <template slot-scope="props">{{ props.row.sciPredictValue | numFormat(2,'-') }}</template>
                    </el-table-column>
                   
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="bold">{{ (resultDetail.currentRow||{}).divisorName }}</div>
              <div class="margin-top-10"><span class="color-sub">卓创赋值说明：</span>{{ (resultDetail.currentRow||{}).reason }}</div>
              <Chart :option="resultChart" class="flex-auto"></Chart>
            </el-col>
          </el-row> 
           
            
         


      </el-dialog>

  </div>
</template>

<script>
import Chart from "@/components/Chart";
import exportExcel  from "@/common/exportExcel";
export default {
  name: 'ForecastResults',
  components:{
    Chart,
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    },
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data (){
    return{

      multipleSelection: [],//勾选的列表
      cycle:'',
      loading:false,
      dataList: [],
      tableKey:Math.random(),
      resultDetail:{//预测结果弹窗
        visible:false,
        loading:false,
        data:[],
        currentRow: null
      },
      activeRow:{}
    }
  },
  computed:{
    //预测结果图表
    resultChart(){
      let chartData=this.resultDetail.currentRow||{}
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: 0
            }
          }
        })
        opt.yAxis.name=chartData.items[0].unit
        opt.dataZoom.startValue=chartData.dataList.findIndex(v=>this.$numFormat(v.aiPredictValue))-5
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            type: 'line', 
            id:v.id,
            connectNulls:true,
            lineStyle:{
              type:v.id!='hisValue'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!chartData.dataList.slice(0,val.dataIndex).every(v=>!v[item.id])){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },

  },
  created(){
    this.getData()
  },
  mounted(){

  },
  methods:{
    async getData(){
      await this.getPredictCycle()
      if(this.cycle){
        this.getPredictPlan()
      }
    },

    //获取预测期
    getPredictCycle(){
      this.loading=true
      let request=this.$instance.get('/resultView/getPredictCycle',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.cycle=res.data.info
        }else{
          this.cycle=''
        }
      })
      request.catch(()=>{
        this.loading=false
        this.cycle=''
      })
      return request
    },
    //获取列表信息
    getPredictPlan(){
      this.loading=true
      let request=this.$instance.get('/resultView/myComparisonView',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           startCycle:this.cycle,
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.dataList=res.data.info
          this.tableKey=Math.random()
        }else{
          this.dataList=[]
        }
      })
      request.catch(()=>{
        this.loading=false
        this.targetInfo=[]
      })
      return request
    },
    //获取模型构成
    getDivisorsOfModel(row){
      this.activeRow=row
      this.resultDetail.visible=true
      this.resultDetail.loading=true
      let request=this.$instance.get('/resultView/getDivisorsOfModel',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           predictCycle:this.cycle,
           aiPublishId:row.aiPublishId,
           sciPublishId:row.sciPublishId,
           modelId:row.modelId
          },
      })
      request.then(res=>{
        this.resultDetail.loading=false
        if(res.data.code==0){
          this.resultDetail.data=res.data.info
          this.$nextTick(()=>{
            this.$refs.divisorsTable.setCurrentRow(this.resultDetail.data[0]);
          })
          
        }else{
          this.resultDetail.data=[]
        }
      })
      request.catch(()=>{
        this.resultDetail.loading=false
        this.resultDetail.data=[]
      })
      return request
    },
    handleCurrentChange(val) {
      this.resultDetail.currentRow = val;
    },

    //导出
    downLoadDataByModelResult(){
      let query={
        modelFreq:this.modelFreq,
        startCycle:this.cycle
      }
      exportExcel('/resultView/exportModelResult',query,'get');
    },
    downLoadDataByDivisorResult(){
      let query={
        modelFreq:this.modelFreq,
        predictCycle:this.cycle,
        aiPublishId:this.activeRow.aiPublishId,
        modelId:this.activeRow.modelId
      }
      exportExcel('/resultView/exportModelOfDivisorResult',query,'get');
    },
  },
  
  watch: {
    "modelFreq" : {
     handler:function(){
      this.getData()
     },
     deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style lang="scss">

  .forecast-results-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>